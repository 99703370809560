<template>
  <setup-table
    :title="`Additional Invoice Cost`"
    :items="items"
    :headers="headers"
    :loading="loading"
    :clearItem="!showForm || !editedItem.id"
    @createItem="createItem()"
    @editItem="editItem"
    @deleteItems="deleteItems"
  >
    <template #itemForm v-if="showForm">
      <additional-invoice-costs-form
        ref="additionalInvoiceCostsForm"
        :value="editedItem"
        @submit="saveItem"
        @closeForm="closeForm"
        @formChanges="formChanges"
      ></additional-invoice-costs-form>
    </template>

    <template #[`item.amount`]="{ item }">
      <p class="mb-0">${{ item.amount }}</p>
    </template>
    <template #[`item.displaySection`]="{ item }">
      <p class="mb-0">{{ item.displaySection ? 'Vehicle' : 'Driver' }}</p>
    </template>
  </setup-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  GET_ADDITIONAL_INVOICE_COSTS,
  SAVE_ADDITIONAL_INVOICE_COST,
  DELETE_ADDITIONAL_INVOICE_COSTS,
} from '@/store/modules/AdditionalInvoiceCost/actions';
import SetupTable from './SetupTable.vue';
import AdditionalInvoiceCostsForm from './AdditionalInvoiceCostsForm.vue';
import { toMoney } from '@/util';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';
import { cloneDeep } from 'lodash';

export default {
  name: 'AdditionalInvoiceCostsSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, AdditionalInvoiceCostsForm },
  data() {
    return {
      toMoney,
      showForm: false,
      items: [],
      editedItem: {},
      loading: true,
      headers: [
        { text: 'Charge Type', value: 'chargeType' },
        { text: 'Description', value: 'description' },
        { text: 'Amount', value: 'amount' },
      ],
    };
  },
  computed: {
    ...mapGetters('additionalInvoiceCost', ['additionalInvoiceCosts']),
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('additionalInvoiceCost', [
      GET_ADDITIONAL_INVOICE_COSTS,
      SAVE_ADDITIONAL_INVOICE_COST,
      DELETE_ADDITIONAL_INVOICE_COSTS,
    ]),
    async fetchItems() {
      await this.getAdditionalInvoiceCosts({ forTable: true });
      this.items = this.additionalInvoiceCosts;
      this.loading = false;
    },
    async createItem() {
      const yes = await this.performPromptBeforeLeave();

      if (!yes) return;
      this.showForm = true;
      this.editedItem = { id: 0 };
      this.baseFormValue = {
        id: 0,
        chargeType: '',
        description: '',
        amount: '0',
      };
      this.latestFormValue = cloneDeep(this.baseFormValue);
    },
    async editItem(item) {
      const yes = await this.performPromptBeforeLeave();

      if (!yes) return;
      this.editedItem = { ...item };
      this.baseFormValue = cloneDeep(this.editedItem);
      this.latestFormValue = cloneDeep(this.editedItem);
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const response = await this.saveAdditionalInvoiceCost(item);
        if (response && response.id) {
          this.$myalert.success('Additional Invoice Cost saved');
          await this.fetchItems();
          this.editedItem = { ...item, id: response.id };
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          items.length > 1 ? 'these Additional Invoice Costs' : 'this Additional Invoice Cost'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const response = await this.deleteAdditionalInvoiceCosts(items.map((e) => e.id));
        if (response.done) {
          this.$myalert.success(`${items.length > 1 ? 'Additional Invoice Costs' : 'Additional Invoice Cost'} deleted`);
          this.showForm = false;
          this.fetchItems();
        }
      }
    },
    closeForm() {
      this.showForm = false;
      this.baseFormValue = null;
      this.latestFormValue = null;
    },
    formChanges(value) {
      this.latestFormValue = cloneDeep(value);
    },
  },
  mixins: [promptBeforeLeave],
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
