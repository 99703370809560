var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancel(false)
                        },
                      },
                    },
                    [_vm._v(" cancel ")]
                  ),
                  _vm.canEditSetupTables
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: { color: "green", dark: "" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" save ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-form",
            {
              attrs: { readonly: _vm.value.id > 0 && !_vm.canEditSetupTables },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "9" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.chargeType,
                              label: "Charge Type",
                              required: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.chargeType
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.chargeType.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.chargeType.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.chargeType,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.chargeType.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "9" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.description,
                              label: "Description",
                              required: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.description
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.description.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.description.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.description,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.description.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.amount,
                              label: "Amount",
                              required: "",
                              type: "number",
                              "prepend-inner-icon": "mdi-currency-usd",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.amount
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.amount.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.amount.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.amount,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.amount.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }